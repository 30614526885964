import React from 'react'
import Section from '../Common/Section'
import Container from '../Common/Container'

import * as s from './NewsSectionBlock.module.scss'

interface NewsSectionBlockProps {
  date?: string
}

const NewsSectionBlock: React.FC<NewsSectionBlockProps> = ({
  date,
  children,
}) => {
  return (
    <Section className={s.section}>
      <Container>
        {date && <div className={s.date}>{date}</div>}
        {children}
      </Container>
    </Section>
  )
}

export default NewsSectionBlock
