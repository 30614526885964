import React from 'react'
import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import Link from '../../components/Common/Link'
import * as s from '../../pages/news.module.scss'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const BimInPractice2024: React.FC = () => {
  return (
    <>
      <SEO
        title="Конференция «BIM на практике 2024»"
        description="Конференция «BIM на практике 2024» -  важное событие для специалистов в области BIM-технологий. Программа мероприятия состояла из 6 уникальных сессий. Компания ООО «Сигнал формат (SIGNAL)» стала партнером сессии «Среда Общих Данных: управление проектом, проектированием, безбумажная стройка и новая ценность BIM»"
      />
      <NewsSectionBlock date="08.10.2024">
        <Typography variant="h1" color="blue">
          Конференция «BIM&nbsp;на&nbsp;практике&nbsp;2024»
        </Typography>
        <Typography variant="body1">
          <Link
            href="https://biminpractice.ru/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Конференция «BIM&nbsp;на&nbsp;практике&nbsp;2024»
          </Link>{' '}
          стала важным событием для специалистов в области BIM-технологий.
          Программа мероприятия состояла из 6 уникальных сессий, где участники
          обменивались опытом и обсуждали актуальные вопросы, а также новые
          направления в цифровизации строительной отрасли. На мероприятии
          выступило 50 экспертов, которые поделились своими успешными кейсами
          внедрения BIM-технологий, что позволило участникам Конференции увидеть
          практическое применение теоретических знаний.
        </Typography>
        <Typography variant="body1">
          Компания{' '}
          <Typography
            variant="body2"
            color="blue"
            onClick={() => navigate('/')}
          >
            ООО «Сигнал Формат (SIGNAL)»
          </Typography>{' '}
          стала партнером сессии «Среда Общих Данных: управление проектом,
          проектированием, безбумажная стройка и новая ценность BIM».
        </Typography>
        <Typography variant="body1">
          Модератором сессии выступил Биктимиров Константин, старший партнер{' '}
          <Link
            href="https://pssbim.ru/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            ООО&nbsp;«ПСС»
          </Link>
          , сертифицированный Project Manager Professional. Его опыт
          способствовал конструктивному обсуждению ключевых вопросов, касающихся
          управления проектами и внедрения безбумажных решений. Спикерами от
          компании-партнера стали Казанцев Сергей, директор по цифровому
          строительству, который представил инновационные подходы к
          проектированию и управлению строительными процессами, и Попов
          Александр, технический директор, который поделился знаниями о новых
          технологиях и их применении в рамках безбумажной стройки и BIM.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/bim-in-practice-2024/image-1.jpg"
            alt='Конференция "BIM на практике 2024"'
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          В современном мире, где информация играет решающую роль, управление
          данными становится неотъемлемой частью успешного ведения бизнеса. В
          ходе прошедшей сессии, посвященной вопросам цифровизации в
          строительной отрасли, участники обсудили, как применение современных
          сервисов для коммуникации между всеми участниками проекта способствует
          поддержанию прозрачности и актуальности данных. Это, в свою очередь,
          значительно повышает общую эффективность процессов и снижает
          вероятность ошибок, что критически важно в условиях жесткой
          конкуренции на рынке.
        </Typography>
        <Typography variant="body1">
          Одним из ключевых инструментов, выделенных в ходе обсуждений, стала
          Среда Общих Данных. Этот инструмент становится основой для многих
          компаний, стремящихся к цифровизации своих процессов. В условиях
          ограниченности ресурсов эффективное управление цифровыми данными
          становится жизненно необходимым для успешного завершения проектов.
        </Typography>
        <Typography variant="body1">
          Участники сессии рассмотрели, каким образом девелоперские компании
          могут стать главными выгодоприобретателями от внедрения Среды Общих
          Данных. Обсуждение также затронуло связь между функциями этой среды и
          эффективными процессами BIM, что подчеркивает важность интеграции
          технологий для повышения производительности и качества работ.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../../assets/images/news/bim-in-practice-2024/image-2.jpg"
            alt='Конференция "BIM на практике 2024 "'
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Кроме того, особое внимание было уделено требованиям Регулятора в
          части ведения Информационной Модели объекта капитального
          строительства. Участники обменялись мнениями о том, как обеспечить
          соблюдение этих требований и как защитить данные от
          несанкционированного доступа, утечек и потерь. Это является критически
          важным аспектом в условиях современных вызовов кибербезопасности, и
          вопросы защиты данных стали одним из центральных моментов обсуждения.
        </Typography>
        <Typography variant="body1">
          Сессия собрала ведущих экспертов отрасли и предоставила платформу для
          конструктивного обмена опытом и идеями, что способствовало более
          глубокому пониманию текущих трендов и вызовов в цифровизации
          строительной отрасли. Результаты обсуждений помогли участникам
          сформировать более четкое представление о путях внедрения
          инновационных решений и оптимизации процессов, что в итоге приведет к
          повышению общей зрелости отрасли в принятии новых технологий.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimInPractice2024
